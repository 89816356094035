import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-size-lg" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = { class: "font-italic py-3" }
const _hoisted_4 = { class: "font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WCModalHeader = _resolveComponent("WCModalHeader")!
  const _component_WCModalBody = _resolveComponent("WCModalBody")!
  const _component_WCModalFooter = _resolveComponent("WCModalFooter")!
  const _component_WCModal = _resolveComponent("WCModal")!

  return (_openBlock(), _createBlock(_component_WCModal, {
    position: "center",
    onDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dismiss($data.MODAL_EVENTS.DISMISS)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_WCModalHeader, { class: "align-items-baseline" }, {
        default: _withCtx(() => [
          _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('resetIndex')), 1),
          _createTextVNode(),
          _createElementVNode("button", {
            class: "btn btn-link underline-link-from-center",
            "data-testid": "cancel-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close($data.MODAL_EVENTS.HEADER_CANCEL)))
          }, _toDisplayString(_ctx.$t('cancel')), 1)
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createVNode(_component_WCModalBody, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('resetIndexWarning')), 1),
            _createTextVNode(),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('resetIndexNote')), 1),
            _createTextVNode(),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('resetIndexConfirmation')), 1)
          ])
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createVNode(_component_WCModalFooter, null, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close($data.MODAL_EVENTS.CONFIRM))),
            class: "btn btn-outline-primary",
            "data-testid": "confirm-reset"
          }, _toDisplayString(_ctx.$t('yesContinue')), 1),
          _createTextVNode(),
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dismiss($data.MODAL_EVENTS.CANCEL))),
            class: "btn btn-outline-dark font-size-sm",
            "data-testid": "cancel-reset"
          }, _toDisplayString(_ctx.$t('noCancel')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}